/*----------------------------------------------------

	JSPOPUP.JS

----------------------------------------------------*/

var popup = '';

// parent function to run through the steps of the process
function initalise(){

  // does browser include main css file?
  // so popup doesn't open in checkout style pages that use different stylesheet
  if(document.getElementById('css--screen-normal').length == 0){
    return false;
  }

  // has user seen popup in last 31 days?
  // return true variant used in jsOnload as main loading criteria
  if(getCookie('sopopup--ageverification')){
    return false;
  }

  // create popup
  createPopup();

}

initalise();




// create actual html for popup and output
function createPopup(){

  // create basic overlay

  var popup_html = document.createElement('div');
  popup_html.setAttribute('class','ageverification__outer');
  popup_html.innerHTML = '<div class="ageverification"></div>';

  document.body.appendChild(popup_html);

  popup = document.querySelector(".ageverification");

  // create html for body of popup
  var popuphtml = '';
  popuphtml += '<h1>Are you 18 or older?</h1>';
  popuphtml += '<div class="ageverification__buttons">';
    popuphtml += '<button class="ageverification--yes button primary">Yes</button>';
    popuphtml += '<button class="ageverification--no button primary">No</button>';
  popuphtml += '</div>';
  popuphtml += '<p>You must verify that you are 18 years of age or older to enter this website</p>';

  // add html to popup
  popup.innerHTML = popuphtml;


}



// remove popup when closed
function removePopup(obj){

  obj.parentNode.removeChild(obj);

  // drop cookie on close
  setCookie('sopopup--ageverification',1,31);

}




// triggers for dropping cookie on postive click
document.querySelector('.ageverification--yes').addEventListener('click', function(ev) {
  ev.preventDefault();
  var popup_outer = document.querySelector('.ageverification__outer');
  removePopup(popup_outer);
  setCookie('sopopup--ageverification',1,31);
});

// triggers redirection to google on negative click
document.querySelector('.ageverification--no').addEventListener('click', function(ev) {
  ev.preventDefault();
  window.location.href = "https://google.com";
});
